import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import colors from "../utils/colors"
import formatDate from "../utils/formatDate"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ContentContainer,
  PageHeading,
  StyledDate,
  StyledLink,
} from "../components/shared-styles"
import Github from "../images/svg/github.svg"
import Linkedin from "../images/svg/linkedin.svg"
import Twitter from "../images/svg/twitter.svg"

const Hero = styled.div`
  background-image: url(${props => props.backgroundImageSrc});
  background-repeat: no-repeat;
  background-size: cover;
`

const Title = styled.h2`
  max-width: 24rem;
  color: ${colors.white};
  padding: 4rem 1rem 0 1rem;
  margin: 0;
`

const SocialIcons = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-bottom: 4rem;

  svg {
    width: 36px;
  }

  path {
    fill: white;
  }

  a:not(:last-child) {
    margin-right: 0.5rem;
  }
`

const StyledSection = styled.section`
  background-color: ${colors.white};
`

const PostsContainer = styled(ContentContainer)`
  background-color: ${colors.white};
`

const StyledHeading = styled.h3`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  &:hover {
    color: ${colors.red};
  }
`

const IndexPage = ({ data }) => {
  const blogPosts = data.allContentfulBlogPost.edges
  const home = data.contentfulHome
  return (
    <Layout>
      <SEO title="Daniel D. Pence | Home" />
      <Hero backgroundImageSrc={home.image.file.url}>
        <ContentContainer>
          <Title
            dangerouslySetInnerHTML={{
              __html: home.hero.childMarkdownRemark.excerpt,
            }}
          />
          <SocialIcons>
            <a
              href="https://github.com/danieldpence"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Github />
            </a>
            <a
              href="https://twitter.com/danieldpence"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
            <a
              href="https://www.linkedin.com/in/danieldpence/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
          </SocialIcons>
        </ContentContainer>
      </Hero>
      <StyledSection>
        <PostsContainer>
          <PageHeading>Recent Posts</PageHeading>
          {blogPosts.map(({ node: post }) => (
            <div key={post.id}>
              <StyledDate>{formatDate(post.createdAt)}</StyledDate>
              <Link to={`/post/${post.slug}`}>
                <StyledHeading>{post.title}</StyledHeading>
              </Link>
              <p>
                {
                  post.childContentfulBlogPostBodyTextNode.childMarkdownRemark
                    .excerpt
                }
                <br />
                <StyledLink to={`/post/${post.slug}`}>Read More →</StyledLink>
              </p>
              <hr />
            </div>
          ))}
        </PostsContainer>
      </StyledSection>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageBlogPostsQuery {
    allContentfulBlogPost(
      limit: 1000
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          childContentfulBlogPostBodyTextNode {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 150)
            }
          }
          image {
            file {
              url
            }
          }
          tags
          createdAt
        }
      }
    }
    contentfulHome {
      image {
        file {
          url
        }
      }
      hero {
        childMarkdownRemark {
          html
          excerpt
        }
      }
    }
  }
`
